<template>
  <div class="fristcon" v-if="infoList">
    <div class="fristcon-tit">{{ projactNanme }}_{{ historyname }}</div>
    <div>
      <div class="fristcon-cen flex">
        <!-- 顶部左边 -->
        <div class="fristcon-cen-left">
          <div class="fristcon-cen-left-tit">
            {{ titname }}
          </div>
          <div class="btnarr">
            <div class="fristcon-cen-left-btn flex align-cen">
              <div class="text">SP</div>
              <div
                class="num ma-auto"
                @click="toIpt(infoList[node].SP, '设定值', 'SP', node)"
              >
                {{ infoList[node].SP }}
              </div>
            </div>
            <div class="fristcon-cen-left-btn flex align-cen">
              <div class="text">PV</div>
              <div
                class="num ma-auto"
                @click="toIpt(infoList[node].PV, '测量值', 'PV', node)"
              >
                {{ infoList[node].PV }}
              </div>
            </div>
            <div class="fristcon-cen-left-btn flex align-cen">
              <div class="text">AV</div>
              <div
                class="num ma-auto"
                @click="toIpt(infoList[node].AV, '总输出', 'AV', node)"
              >
                {{ infoList[node].AV }}
              </div>
            </div>
          </div>
          <div class="btnarr1">
            <div class="row">
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">TL1</div>
                  <div
                    class="num"
                    @click="
                      toIpt(
                        infoList[node].TL1,
                        '软伺服作用时间，下位已经全部将四个作为时间参数统一，只需修改TL1',
                        'TL1',
                        node
                      )
                    "
                  >
                    {{ infoList[node].TL1 }}
                  </div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">TL2</div>
                  <div class="num">{{ infoList[node].TL2 }}</div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">TL3</div>
                  <div class="num">{{ infoList[node].TL3 }}</div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">TL4</div>
                  <div class="num">{{ infoList[node].TL4 }}</div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">E1</div>
                  <div
                    class="num"
                    @click="
                      toIpt(infoList[node].E1, '软伺服偏差区间1', 'E1', node)
                    "
                  >
                    {{ infoList[node].E1 }}
                  </div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">E2</div>
                  <div
                    class="num"
                    @click="
                      toIpt(infoList[node].E2, '软伺服偏差区间2', 'E2', node)
                    "
                  >
                    {{ infoList[node].E2 }}
                  </div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">E3</div>
                  <div
                    class="num"
                    @click="
                      toIpt(infoList[node].E3, '软伺服偏差区间3', 'E3', node)
                    "
                  >
                    {{ infoList[node].E3 }}
                  </div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">E4</div>
                  <div
                    class="num"
                    @click="
                      toIpt(infoList[node].E4, '软伺服偏差区间4', 'E4', node)
                    "
                  >
                    {{ infoList[node].E4 }}
                  </div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">A01</div>
                  <div
                    class="num"
                    @click="
                      toIpt(
                        infoList[node].AO1,
                        '软伺服偏差区间1对应输出幅度1',
                        'AO1',
                        node
                      )
                    "
                  >
                    {{ infoList[node].AO1 }}
                  </div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">AO2</div>
                  <div
                    class="num"
                    @click="
                      toIpt(
                        infoList[node].AO2,
                        '软伺服偏差区间2对应输出幅度2',
                        'AO2',
                        node
                      )
                    "
                  >
                    {{ infoList[node].AO2 }}
                  </div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">AO3</div>
                  <div
                    class="num"
                    @click="
                      toIpt(
                        infoList[node].AO3,
                        '软伺服偏差区间3对应输出幅度3',
                        'AO3',
                        node
                      )
                    "
                  >
                    {{ infoList[node].AO3 }}
                  </div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">AO4</div>
                  <div
                    class="num"
                    @click="
                      toIpt(
                        infoList[node].AO4,
                        '软伺服偏差区间4对应输出幅度4',
                        'AO4',
                        node
                      )
                    "
                  >
                    {{ infoList[node].AO4 }}
                  </div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">TL</div>
                  <div
                    class="num"
                    @click="
                      toIpt(
                        infoList[node].TL,
                        '防抖时间，同时对软伺服和重叠控制起作用',
                        'TL',
                        node
                      )
                    "
                  >
                    {{ infoList[node].TL }}
                  </div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">MD</div>
                  <div
                    class="num"
                    @click="
                      toIpt(infoList[node].MD, '输出控制量程下限', 'MD', node)
                    "
                  >
                    {{ infoList[node].MD }}
                  </div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">MU</div>
                  <div
                    class="num"
                    @click="
                      toIpt(infoList[node].MU, '输出控制量程上限', 'MU', node)
                    "
                  >
                    {{ infoList[node].MU }}
                  </div>
                </div>
              </div>
              <div class="row-couml flex justify-content-cen">
                <div class="list-num flex">
                  <div class="text">ZFK</div>
                  <div
                    class="num"
                    @click="
                      toIpt(
                        infoList[node].ZF_K,
                        '输出控制量程上限',
                        'ZF_K',
                        node
                      )
                    "
                  >
                    {{ infoList[node].ZF_K }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 顶部曲线图 -->
        <div class="fristcon-cen-cen">
          <div id="rts_Chart" v-loading="loading" :option="option"></div>
        </div>
        <div class="fristcon-cen-bot">
          <div class="typedata">
            <div class="typedatabox" style="color: #1799cd; margin-bottom: 1vh">
              <div class="flex">设定值</div>
              <div>
                Y[{{ rightarr[0] ? rightarr[0].data : infoList[node].SP }}]
              </div>
              <div>Y[{{ name[0] }}]</div>
            </div>
            <div
              class="typedatabox typedatabox1"
              style="color: #0de0ac; margin-bottom: 1vh"
            >
              <div>测量值</div>
              <div>
                Y[{{ rightarr[1] ? rightarr[1].data : infoList[node].PV }}]
              </div>
              <div>Y[{{ name[1] }}]</div>
            </div>
            <div class="typedatabox" style="color: #ec163e">
              <div>总输出</div>
              <div>
                Y[{{ rightarr[2] ? rightarr[2].data : infoList[node].AV }}]
              </div>
              <div>Y[{{ name[2] }}]</div>
            </div>
          </div>
        </div>
      </div>
      <div class="fristcon-cen-btn">
        <div class="btn-f flex">
          <div
            class="btn-f-btn"
            @click="serchDt(10)"
            :class="{ current: active == 10 }"
          >
            {{ dtname }}
          </div>
          <div
            class="btn-f-btn"
            @click="serchHis(1, 6, 'up')"
            :class="{ current: active == 6 }"
          >
            前翻一页
          </div>
          <div
            class="btn-f-btn"
            @click="serchHis(0.5, 7, 'up')"
            :class="{ current: active == 7 }"
          >
            前翻半页
          </div>
          <div
            class="btn-f-btn"
            @click="serchHis(0.5, 8, 'down')"
            :class="{ current: active == 8 }"
          >
            后翻半页
          </div>
          <div
            class="btn-f-btn"
            @click="serchHis(1, 9, 'down')"
            :class="{ current: active == 9 }"
          >
            后翻一页
          </div>
          <div
            class="btn-f-btn1"
            @click="serchHis(1, 0)"
            :class="{ current: selectedTime === 1 }"
            >
            1h
          </div>
          <div
            class="btn-f-btn1"
            @click="serchHis(2, 1)"
            :class="{ current: selectedTime === 2 }"
            >
            2h
          </div>
          <div
            class="btn-f-btn1"
            @click="serchHis(4, 2)"
            :class="{ current: selectedTime === 4 }"
            >
            4h
          </div>
          <div
            class="btn-f-btn1"
            @click="serchHis(8, 3)"
            :class="{ current: selectedTime === 8 }"
            >
            8h
          </div>
          <div
            class="btn-f-btn1"
            @click="serchHis(24, 4)"
            :class="{ current: selectedTime === 24 }"
            >
            24h
          </div>
          <div
            class="btn-f-btn1"
            @click="serchHis(72, 5)"
            :class="{ current: selectedTime === 72 }"
            >
            72h
          </div>
        </div>
      </div>
      <div class="fristcon-zd flex">
        <div
          class="btnarr1-btn"
          :style="{
            background: infoList[node].TS ? '#0ef7ff' : '',
            color: infoList[node].TS ? '#25495e' : '',
          }"
          @click="toIpt(infoList[node].TS, '点击按钮', 'TS', node)"
        >
          {{ infoList[node].TS ? "跟踪" : "自动" }}
        </div>
        <div
          class="btnarr1-btn"
          @click="toIpt(infoList[node].AD, '点击按钮', 'AD', node)"
        >
          {{ infoList[node].AD ? "反作用" : "正作用" }}
        </div>
      </div>
      <div class="fristcon-bot">
        <div class="fristcon-bot-row1 flex">
          <div class="row4 flex align-cen">
            <div class="time-tit">指定时间:</div>
            <el-date-picker
              v-model="value2"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="-"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
            <div class="row4-btn" @click="sech">查询</div>
          </div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
  </div>
</template>
<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import { downAction } from "@/api/index/index";
import index from "./_index/index.js";
export default {
  components: { inputVal },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data() {
    return {
      addnember: "",
      dataview: {},
      Lkname: "",
      Hhistoryname: "",
      Hnode: "",
      isHshow: false,
      historyname: "",
      node: "",
      titname: "",
      xAxis: [],
      echartData: [],
      legendData: [],
      timer: null,
      myChart: "",
      loading: true,
      echartcolor: [],
      end_time: "",
      start_time: "",
      scaleHeight: "",
      name: [],
      name1: [],
      mark: [],
      nodeList: [],
      option: "",
      value2: "",
      havetrue: "",
      current: "current",
      active: 10,
      now: true,
      baseTime: new Date(), // 时间窗口的基准时间（通常是窗口结束时间）
      currentDuration: 1, // 当前选中的时间跨度（小时数）
      timeOffset: 0, // 时间偏移量（用于累计翻页）
      selectedTime: null, // 选中的时间范围
      dtname: "实时趋势",
      projectData: {},
      qcty: "",
      jsostr: "",
    };
  },
  mixins: [index],
  watch: {
    option: {
      handler(newVal, oldVal) {
        if (this.myChart) {
          if (newVal) {
            this.loading = false;
            this.myChart.setOption(newVal, true);
          } else {
            this.loading = false;
            this.myChart.setOption(oldVal);
          }
        } else {
          this.loading = false;
          this.drawLine();
        }
      },
    },
    rightarr1: {
      handler(newVal, oldVal) {
        if (this.rightarr) {
          if (newVal) {
            this.rightarr(newVal, true);
          } else {
            this.rightarr(oldVal);
          }
        }
      },
    },
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    // rightarr
  },
  created() {},
  mounted() {
    this.getInfo();
    this.timer = setInterval(() => {
      setTimeout(this.getInfo(), 0);
    }, 5000);
    // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.timer);
    });
    if (this.value2) {
      this.havetrue = "至";
    }
  },
  methods: {
    toCompon(key, name, name2, name3) {
      this.isIndex = key;
      this.Hhistoryname = name;
      this.Hnode = name2;
      this.Lkname = name3;
      switch (key) {
        case 0:
          return (this.isMshow = true);
        case 1:
          return (this.isFshow = true);
        case 2:
          return (this.isHshow = true);
      }
    },
    isClose1() {
      this.isHshow = false;
    },
    // 时间段查询
    sech() {
      this.start_time = this.value2[0];
      this.end_time = this.value2[1];
      this.getInfo();
    },
    // 历史实时
    serchDt(index) {
      this.now = !this.now;
      if (this.now) {
        this.active = index;
        this.dtname = "实时趋势";
        this.start_time = "";
        this.timer = setInterval(() => {
          setTimeout(this.getInfo(), 0);
        }, 5000);
      } else {
        this.active = "-1";
        this.dtname = "历史趋势";
        this.getInfo();
        clearInterval(this.timer);
      }
    },
    chanereast(val, name, mark, node) {
      this.$confirm(name, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (typeof val == "boolean") {
            val = !val;
          }
          downAction({
            device: this.projectData.device,
            mark: mark,
            node: node,
            value: val,
            cp_type: this.projectData.type,
          })
            .then((res) => {
              if (res.info.res == 1) {
                this.infoList[node].mark = val;
              } else {
                this.$message.error("出错了");
              }
            })
            .catch((err) => {
              this.$message.error("下置失败");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 查询历史数据
    serchHis(val, i, type) {
      if (!this.now) {
        this.value2 = [];
        if (!this.baseTime || this.currentDuration === 0) {
          this.baseTime = new Date();
          this.currentDuration = 1; // 默认1小时
          this.timeOffset = 0;
        }
        if (type === "time") {
          if (this.selectedTime === val) {
            // 再次点击，取消选中
            this.selectedTime = null;
            this.active = -1;
            return;
          } else {
            // 点击新的时间按钮，更新选中状态和时间跨度
            this.selectedTime = val;
            this.currentDuration = val; // 点击时间按钮时初始化基准点
            this.baseTime = new Date(); // 基准时间默认为当前时间
            this.timeOffset = 0; // 重置偏移量
            this.active = i;
          }
        } else {
          // 处理翻页时的偏移量
          const offsetHours =
            type === "up"
              ? -val * (this.selectedTime || this.currentDuration)
              : val * (this.selectedTime || this.currentDuration);
          this.timeOffset += offsetHours;

          // 后翻不能超过当前时间
          const maxTime = new Date();
          if (
            type === "down" &&
            this.baseTime.getTime() + this.timeOffset * 3600000 >
              maxTime.getTime()
          ) {
            this.$message.warning("已经是最新的时间了");
            this.timeOffset -= offsetHours; // 撤销偏移
            return;
          }
        }

        // 统一计算时间范围
        const endTime = new Date(
          this.baseTime.getTime() + this.timeOffset * 3600000
        );
        const startTime = new Date(
          endTime.getTime() -
            (this.selectedTime || this.currentDuration) * 3600000
        );

        // 更新到数据
        this.start_time = this.formatDate(startTime, "yyyy-MM-dd hh:mm:ss");
        this.end_time = this.formatDate(endTime, "yyyy-MM-dd hh:mm:ss");
        this.active = i;

        // 调用接口获取数据
        this.getInfo();
      } else {
        this.$message.error("请先切换为历史趋势");
      }
    },
    // 获取时间并格式化
    getTimeData(val, baseDate = new Date()) {
      const dt = new Date(baseDate);
      val === 0 ? "" : dt.setTime(dt.getTime() - val * 60 * 60 * 1000);
      const y = dt.getFullYear();
      const m = (dt.getMonth() + 1 + "").padStart(2, "0");
      const d = (dt.getDate() + "").padStart(2, "0");
      const hh = (dt.getHours() + "").padStart(2, "0");
      const mm = (dt.getMinutes() + "").padStart(2, "0");
      const ss = (dt.getSeconds() + "").padStart(2, "0");
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`; // 返回格式：2021-04-09 09:34:00
    },
    // 获取当前时间并格式化
    formatDate(date, format) {
      const year = date.getFullYear(); // 年
      const month = (date.getMonth() + 1 + "").padStart(2, "0"); // 月
      const day = (date.getDate() + "").padStart(2, "0"); // 日
      const hour = (date.getHours() + "").padStart(2, "0"); // 时
      const minute = (date.getMinutes() + "").padStart(2, "0"); // 分
      const second = (date.getSeconds() + "").padStart(2, "0"); // 秒

      if (format === "yyyy-MM-dd hh:mm:ss") {
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      }
      return ""; // 其他格式可以扩展
    },
    // 自动手动跟踪
    chaneStu(val, mark, node) {
      downAction({
        device: this.projectData.device,
        mark: mark,
        node: node,
        value: val,
        cp_type: this.projectData.type,
      })
        .then((res) => {
          if (res.info.res == 1) {
            this.infoList[node].RM = val;
          } else {
          }
        })
        .catch((err) => {});
    },
    // 获取输入框组件传过来的值
    DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
    //    打开输入框组件。
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
  },
  destroyed() {
    // sessionStorage.removeItem('infoList')
  },
};
</script>
<style lang="scss" scoped>
.el-main {
  width: 100% !important;
}
.main-top {
  width: 100%;
  height: 5vh;
}
.current {
  color: #0ef7ff !important;
  border: 1px solid #0ef7ff;
  box-sizing: border-box;
}
.fristcon {
  width: 100vw;
  height: 92vh;
  // margin-left: -2.5vw;
  overflow: hidden;
  background-image: linear-gradient(#042643, #042643),
    linear-gradient(
      0deg,
      #0b385f 3%,
      #064468 30%,
      #005071 57%,
      #045373 74%,
      #085575 100%
    );
  background-blend-mode: normal, normal;
  .fristcon-tit {
    width: 90vw;
			height: 6vh;
    // background: url("~@/assets/images/ding1.png") no-repeat center center;
    // background-size: 100% 100%;
    margin:0vh 2vw;
    text-align: center;
    line-height: 3.5vh;
    color: #0ef7ff;
  	font-size: 1.5vw;
    // border:#0ef7ff 1px solid;
  }

  .fl {
    float: left;
  }
  .fristcon-cen {
    .fristcon-cen-left {
      width: 13vw;
      height: 56vh;
      background: rgba(0, 22, 36, 0.2);
      // background-image: linear-gradient(to right, rgba(0,22,36,.6) , rgba(0,22,36,.2));
      .fristcon-cen-left-tit {
        width: 12vw;
        height: 4vh;
        text-align: center;
        color: #22465b;
        font-size: 1vw;
        background-color: #fff272;
        line-height: 4vh;
      }
      .btnarr {
        // margin-top: 4vh;
        .fristcon-cen-left-btn {
          width: 7vw;
          margin: 0 auto;
          height: 6vh;
          background: url("~@/assets/images/xk2.png") no-repeat center center;
          background-size: 100% 100%;
          margin-top: 1vh;

          .text {
            color: #6acafb;
            font-size: 2vh;
            line-height: 3vh;
            padding-left: 1vw;
            cursor: pointer;
          }
          .num {
            color: #eafeff;
            font-size: 1vw;
            padding-right: 0.5vw;
            cursor: pointer;
          }
        }
      }
      .btnarr1 {
        margin-top: 2vh;
        .btnarr1-btn {
          width: 4vw;
          background: rgba(14, 247, 255, 0.2);
          height: 3vh;
          text-align: center;
          line-height: 3vh;
          color: #0ef7ff;
          font-size: 1vw;
          margin: 0 auto;
          margin-top: 1vh;
          border-radius: 2px;
          cursor: pointer;
        }
      }
    }
    .fristcon-cen-cen {
      width: 82vw;
      height: 72vh;
      border: #0ef7ff 1px solid;
    }
    #rts_Chart {
      width: 100%;
      height: 72vh;
    }
    .fristcon-cen-bot {
      width: 9vw;
      height: 72vh;
      margin-left: 1vw;
      background: rgba(0, 22, 36, 0.2);
      .typedata {
        width: 8vw;
        height: inherit;
        margin-left: 1.5vw;
        margin-top: 1vh;
        .typedatabox {
          div {
            font-family: "MicrosoftYaHei";
            font-size: 1.5vh;
            line-height: 2vh;
          }
        }
      }
    }
  }
  .fristcon-zd {
    position: absolute;
    bottom: 9.17vh;
    left: 12vw;
    .btnarr1-btn {
      width: 4vw;
      background: rgba(14, 247, 255, 0.2);
      height: 3vh;
      text-align: center;
      line-height: 3vh;
      color: #0ef7ff;
      font-size: 1vw;
      margin: 0 auto;
      margin-top: 1vh;
      border-radius: 2px;
      cursor: pointer;
      margin-right: 1vw;
    }
  }
  .fristcon-cen-btn {
    width: 100%;
    margin-top: 2vh;
    margin-left: 4vw;
    position: relative;

    .btn-f {
      width: 60vw;
      height: 3vh;
      margin: 1vh auto;
      .btn-f-btn {
        width: 5vw;
        background: rgba(14, 247, 255, 0.2);
        border-radius: 2px;
        color: rgba(14, 247, 255, 0.5);
        text-align: center;
        line-height: 3vh;
        font-size: 1vw;
        margin-left: 1vw;
        cursor: pointer;
      }
      .btn-f-btn1 {
        width: 2vw;
        background: rgba(14, 247, 255, 0.2);
        border-radius: 2px;
        color: rgba(14, 247, 255, 0.5);
        text-align: center;
        line-height: 3vh;
        font-size: 1vw;
        margin-left: 1vw;
        cursor: pointer;
      }
      .btn-f-btn:first-child {
        margin-left: 0;
      }
    }
  }
  .row {
    width: 9.6vw;
    height: 50vh;
    background: url("~@/assets/images/xk3.png") no-repeat center center;
    background-size: 100% 100%;
    margin-left: 1vw;
    .row-couml {
      padding: 0 0.8vw;
      line-height: 3vh;
      .list-num {
        font-family: "ArialNarrow";
        // width: 50%;

        .text {
          color: #6acafb;
          font-size: 1vw;
          // width: 40%;
          text-align: right;
        }
        .num {
          color: rgba(228, 254, 255, 0.7);
          font-size: 1vw;
          padding-left: 1vw;
          cursor: pointer;
          width: 5vw;
          text-align: right;
        }
      }
      .list-num1 {
        width: 50%;
        .text {
          color: #6acafb;
          font-size: 1.5vh;
          width: 40%;
          text-align: right;
        }
      }
    }
    .row-couml:first-child {
      padding-top: 0.8vh;
    }
  }
  .fristcon-bot {
    width: 5vw;
    margin: 0 auto;
    margin-top: -8vh;

    .fristcon-bot-row1 {
      // position: relative;
      margin-left: -5vw;

      .row:first-child {
        margin-left: 0;
      }
      .row1 {
        width: 13vw;
        height: 19vh;
        margin-left: 1vw;
        .row1-top {
          width: 100%;
          height: 9vh;
          background: url("~@/assets/images/xk1.png") no-repeat center center;
          background-size: 100% 100%;
          .row-couml {
            padding: 0 0.8vw;
            line-height: 3vh;
            .list-num {
              font-family: "ArialNarrow";
              width: 50%;

              .text {
                color: #6acafb;
                font-size: 1.5vh;
                width: 40%;
                text-align: right;
              }
              .num {
                color: #e4feff;
                font-size: 1.5vh;
                padding-left: 1vw;
                cursor: pointer;
              }
            }
            .list-num1 {
              width: 50%;
              .text {
                color: #6acafb;
                font-size: 1.5vh;
                width: 40%;
                text-align: right;
              }
            }
          }
        }
        .row1-top1 {
          .text {
            color: #6acafb;
            font-size: 1.5vh;
            width: 2vw;
            text-align: right;
            line-height: 3vh;
          }
        }
      }
      .row2 {
        width: 13vw;
        height: 19vh;
        margin-left: 1vw;
        background: url("~@/assets/images/xk3.png") no-repeat center center;
        background-size: 100% 100%;
        .list1 {
          width: 25%;
          text-align: center;
          padding-top: 2vh;
          line-height: 3vh;
          div {
            font-size: 1.5vh;
            color: #e4feff;
            cursor: pointer;
          }
          .list-tit {
            color: #6acafb;
          }
        }
      }
      .row-5 {
        .row-couml {
          line-height: 3.5vh;
        }
      }
      .row3 {
        width: 27vw;
        height: 15vh;
        background: url("~@/assets/images/xk3.png") no-repeat center center;
        background-size: 100% 100%;
        margin-left: auto;

        .list-num {
          width: auto !important;
        }
        .text {
          padding-left: 1vw;
        }
      }
      .row4 {
        // position: absolute;
        // right: 0;
        // bottom: 0;
        margin-top: 4vh;
        .time-tit {
          color: #0c78a3;
          font-size: 1vw;
          margin-left: 30vw;
          width: 5vw;
        }
        .row4-btn {
          width: 4vw;
          height: 3vh;
          background: rgba(14, 247, 255, 0.2);
          border-radius: 2px;
          color: rgba(14, 247, 255, 0.5);
          text-align: center;
          line-height: 3vh;
          font-size: 1vw;
          margin-left: 10px;
        }
        .el-range-editor.el-input__inner {
          background: #031d30 !important;
          border: 1px solid #064a6d;
          width: 16vw;
          height: 3vh;
          padding: 3px 5px;
          position: relative;
          z-index: 1;
        }
      }
    }
    .fristcon-bot-row2 {
      // position: relative;
      // margin: 1vw auto;
      margin-left: -10vw;
      .fristcon-bot-row2-left1 {
        width: 28vw;
        background-color: #031d30;
        div {
          color: #397ca3;
          height: 3vh;
          line-height: 3vh;
          font-size: 1.5vh;
          width: 4vw;
          text-align: center;
          position: relative;
        }
        div::after {
          content: "";
          position: absolute;
          height: 1.4vh;
          width: 1px;
          background: #397ca3;
          right: 0;
          top: 0.8vh;
        }
        div:last-child::after {
          content: none;
        }
      }
      .fristcon-bot-row2-left2 {
        width: 42vw;
        height: 1vh;
        background: url("~@/assets/images/bot.png") no-repeat center center;
        background-size: 100% 100%;
        margin-left: 5vw;
        .list-flex1-btn {
          width: 5vw;
          height: 3vh;
          background-color: rgba(14, 247, 255, 0.2);
          border-radius: 2px;
          line-height: 3vh;
          text-align: center;
          cursor: pointer;
          font-size: 1vw;
          color: #209caf;
          margin-left: 15.6vw;
          margin-top: -0.8vh;
        }
        .list-flex1-btn1 {
          margin-left: 21.4vw;
          margin-top: -3vh;
        }
      }
      .condition-time {
        div {
          width: 2vw;
          height: 3vh;
          background-color: rgba(14, 247, 255, 0.2);
          border-radius: 2px;
          line-height: 3vh;
          text-align: center;
          font-size: 1vw;
          color: #209caf;
          margin-right: 3px;
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep {
  .el-range__icon,
  .el-range__close-icon {
    line-height: 2vh !important;
  }
  .el-range__close-icon {
    position: absolute;
    z-index: 2;
    right: 0;
  }
  .el-range__icon {
    display: none;
  }
  .el-range-input {
    background: #031d30 !important;
    color: #fff;
    width: 42%;
    font-size: 0.6vw;
  }
  .el-range-separator {
    color: #fff;
    line-height: 2vh;
  }
}
</style>